<script setup>
import { reactive } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { compressAndUploadImage } from '~/common/composables/compress-and-upload-image.composable.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import { isFileExtensionAllowed } from '~/common/utils/common.utils.js';

const $services = inject('$services');
const $t = inject('$t');
const $toast = inject('$toast');
const form$ = ref(null);

const auth_store = useAuthStore();
const common_store = useCommonStore();
const state = reactive({
  is_loading: false,
  form: {
    name: '',
    properties: { address: '' },
    cover_image: null,
  },
});

function onFormMounted(el$) {
  if (auth_store.current_organization?.uid) {
    el$.update({
      name: auth_store.current_organization.name || '',
      properties: { address: auth_store.current_organization.properties?.address || '' },
      cover_image: auth_store.current_organization.cover_image || null,
    });
  }
}

function deleteCoverImage() {
  form$.value.load({
    ...state.form,
    cover_image: null,
  }, true);
}

async function save() {
  try {
    state.is_loading = true;
    if (!state.form?.cover_image?.url) {
      delete state.form?.cover_image?.__file__;
      if (!isFileExtensionAllowed(state.form.cover_image?.name))
        return null;
      const cover_image_response = await compressAndUploadImage(
        state.form.cover_image,
        $services.organizations.generate_upload_url,
        { maxWidth: 300 },
      );
      if (cover_image_response)
        state.form.cover_image = cover_image_response?.url ? cover_image_response : null;
    }
    const organization = {
      uid: auth_store.current_organization.uid,
      name: state.form.name,
      properties: {
        ...auth_store.current_organization?.properties,
        address: state.form.properties.address,
      },
      cover_image: state.form.cover_image,
    };
    const { data } = await common_store.update_data({
      type: 'patch_update',
      id: auth_store.current_organization.uid,
      data: {
        organization,
      },
      service: 'organizations',
      append_data: false,
      state_prop: 'organizations_map',
      update_state: false,
    });
    if (data?.organization) {
      $toast({
        title: 'Updated!',
        text: 'Organization updated successfully!',
        type: 'success',
      });
      await auth_store.set_logged_in_user_details({ forceUpdate: true });
    }
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
    logger.log(error);
  }
  finally {
    state.is_loading = false;
  }
}
</script>

<template>
  <div>
    <HawkPageHeader class="!px-0">
      <template #title>
        <div>
          <div>{{ $t('General') }}</div>
          <p class="text-sm text-gray-600 font-normal">
            {{ $t("Update organization details") }}
          </p>
        </div>
      </template>
    </HawkPageHeader>
  </div>
  <div class="my-5 text-sm">
    <Vueform
      ref="form$"
      v-model="state.form"
      size="sm"
      :display-errors="false"
      :format-load="(data) => data"
      :endpoint="save"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <div class="max-w-[700px]">
          <TextElement
            :label="$t('Organization Name')"
            name="name"
            :placeholder="$t('Enter name')"
            rules="required"
            class="mb-4"
          />
          <ObjectElement name="properties">
            <TextareaElement
              :label="$t('Address')"
              name="address"
              :placeholder="$t('Enter organization address')"
              class="mb-4"
            />
          </ObjectElement>
          <FileElement
            name="cover_image"
            accept="image/*"
            :drop="true"
            :soft-remove="true"
            :label="$t('Logo')"
            class="mb-4"
            :url="false"
            :auto="true"
            :use_uppy="false"
            :presets="['hawk_file_element']"
            :options="{
              clickable_text: $t('Click to upload'),
              text: $t('or drag and drop'),
              description: $t('SVG, PNG, JPG or GIF'),
            }"
          >
            <template #preview>
              <div
                v-if="state.form.cover_image?.url"
                class="flex items-center justify-between"
              >
                <img
                  :src="state.form.cover_image.url"
                  alt="profile cover"
                  title="profile picture"
                  class="w-40 h-12 p-2 border rounded-lg object-contain"
                >
                <HawkButton
                  v-if="auth_store.check_permission('modify_organization', $route.params.asset_id)"
                  type="plain"
                  color="error"
                  @click="deleteCoverImage()"
                >
                  {{ $t("Remove") }}
                </HawkButton>
              </div>
            </template>
          </FileElement>
        </div>

        <div class="sticky bg-white bottom-0">
          <hr class="my-5">
          <div class="flex justify-end gap-3 pb-5">
            <HawkButton type="outlined" @click="$router.push({ name: 'home' })">
              {{ $t("Cancel") }}
            </HawkButton>
            <ButtonElement
              button-class="w-full bg-blue-600"
              name="submit"
              :submits="true"
              :disabled="!auth_store.check_permission('modify_organization', $route.params.asset_id)"
            >
              {{ $t("Save") }}
            </ButtonElement>
          </div>
        </div>
      </div>
    </Vueform>
  </div>
</template>
